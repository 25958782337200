import { useEffect } from "react";
import { createContext, useState } from "react";
// import usePermission from "./usePermission";

export const GlobalContext = createContext(null);

const GlobalContextProvider = ({ children }) => {
  const [splashLoaded, setSplashLoad] = useState(false);
  const [mandateId, setMandateId] = useState("");
  const [isDenied, setDenied] = useState(false);
  const [otpData, setOtpData] = useState({
    mobile: "",
    path: "",
    method: "",
    vId: "",
  });

  return (
    <GlobalContext.Provider
      value={{
        otpState: [otpData, setOtpData],
        splashState: [splashLoaded, setSplashLoad],
        mandateState: [mandateId, setMandateId],
        isDenied,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
