import React, { useState } from "react";
import { parseRC } from "../api";
import CaptureUpload from "../components/CaptureV2";
import { Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

export default function RC() {
  const [isLoading, setIsLoading] = useState(false);
  const [rcError, setRcError] = useState(false);
  const [rcErrorTxt, setRcErrorTxt] = useState("");
  const [apiPayload, setApiPayload] = useState({
    base64: "",
  });
  const [apiResponse, setApiResponse] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const isValid = () => {
    let valid = true;

    if (apiPayload.base64 === "") {
      setRcError(true);
      setRcErrorTxt("cannot be blank");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    if (isValid()) {
      try {
        const { data } = await parseRC(apiPayload);
        console.log(data);
        setApiResponse(data["details"]);
      } catch (error) {
        enqueueSnackbar(error.response.data.errors[0].message, {
          variant: "error",
        });
        console.log(error);
      }
    }
    setIsLoading(false);
  };

  const handleGoBack = (e) => {
    setApiResponse(null);
    setApiPayload({
      base64: "",
    });
  };

  return !apiResponse ? (
    <>
      <div className="main-card">
        <div className="container-card">
          <h1 className="chooser-head mb-3 mt-3"> Upload RC :</h1>
          <form onSubmit={handleSubmit}>
            <div className="upload-container">
              <CaptureUpload
                error={rcError}
                errorTxt={rcErrorTxt}
                setFile={(file) => {
                  setRcError(false);
                  setRcErrorTxt("");
                  setApiPayload((prev) => ({
                    ...prev,
                    base64: file,
                  }));
                }}
                tempState={apiPayload.base64}
              />
            </div>

            <Button
              className="btn-submit"
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              onClick={handleSubmit}
            >
              {" "}
              {isLoading ? (
                <>
                  <CircularProgress
                    className="me-2 mb-1"
                    size={20}
                    style={{ color: "white", marginRight: "6px" }}
                  />
                  Loading
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="main-card">
        <div className="copy-option">
          <button
            onClick={() => {
              navigator.clipboard.writeText(
                JSON.stringify(apiResponse, null, 2)
              );
              enqueueSnackbar("copied!", {
                variant: "success",
              });
            }}
          >
            <u>Copy</u>
          </button>
        </div>
        <pre style={{ width: "100%", height: "95%" }}>
          {JSON.stringify(apiResponse, null, 2)}
        </pre>
        <Button
          className="btn-submit"
          type="submit"
          fullWidth
          disabled={isLoading}
          variant="contained"
          sx={{ mt: 1, mb: 1 }}
          onClick={handleGoBack}
        >
          {" "}
          {isLoading ? (
            <>
              <CircularProgress
                className="me-2 mb-1"
                size={20}
                style={{ color: "white", marginRight: "6px" }}
              />
              Loading
            </>
          ) : (
            "Go Back"
          )}
        </Button>
      </div>
      <></>
    </>
  );
}
