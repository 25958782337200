import { useState } from "react";
import * as React from 'react';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';


export default function ZoonodleInc() {
  const mystyle = {
    border: "1px solid black"
  };
  const yourstyle = {
    border: "1px solid black",
    width: "100%"
  };

  const [value, setValue] = useState("");
  const [apiRes, setApiRes] = useState([]);
  const [isLoading,setIsLoading]=useState(false);

  const handleSubmit = e => {
    setIsLoading(true);

    var raw = JSON.stringify({
      "url": value
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      mode: 'cors'
    };

    fetch("https://f98fq0sv8h.execute-api.ap-south-1.amazonaws.com/dev/payslip/zoonodle-inc", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.details !== undefined) {
          setApiRes(result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          alert(result.errorMessage + '\n' + result.errorType);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  const handleChange = e => {
      setValue(e.target.value);
  }

  return (
    <>
    {
      isLoading ?
      <h1>Loading.....</h1>
      :
      <>
      <Input onChange={handleChange} style={{width: "70vw"}} placeholder="Enter Public URL here" inputProps={value} />
      <Button onClick={handleSubmit} variant="contained">SUBMIT</Button>

      <table>
        <h1> PaySlip Details will come here : </h1>
        <tbody>
        {
          apiRes.details && Object.keys(apiRes.details).map((value,i)=>{
            return (
              <tr>
              <th className="width-20">{value}</th>
              <td>{apiRes?.details[value]}</td>
              </tr>
            )
          })
        }
        </tbody>
      </table>


      <h1> Earnings will come here : </h1>
      <table style={yourstyle}>
        <thead>
          <th style={mystyle}>Earnings</th>
          <th style={mystyle}>Amount</th>
        </thead>
        <tbody>
          {
            apiRes.earnings && apiRes?.earnings.map((e,i)=>{
              return (
              <tr>
              <td style={mystyle}>{e.Earnings}</td>
              <td style={mystyle}>{e.Amount}</td>
              </tr>
              )
            })
          }
        </tbody>
      </table>


      <h1> Deductions will come here : </h1>
      <table style={yourstyle}>
        <thead>
          <th style={mystyle}>Deductions</th>
          <th style={mystyle}>Amount</th>
        </thead>
        <tbody>
          {
            apiRes.deductions && apiRes?.deductions.map((e,i)=>{
              return (
              <tr>
              <td style={mystyle}>{e.Deductions}</td>
              <td style={mystyle}>{e.Amount}</td>
              </tr>
              )
            })
          }
        </tbody>
      </table>
    </>
      }
      </>
  )
}
