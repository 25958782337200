import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomInputAutocomplete from "../components/AutoComplete";
import { ParsersList } from "../util/constants";
import { Button, CircularProgress } from "@mui/material";

export default function Parser() {
  const navigate = useNavigate();

  const [parser, setParser] = useState("");
  const [parsers, setParsers] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({
    parser: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getParserList();
  }, []);

  const getParserList = () => {
    for (const parser in ParsersList) {
      parsers.push(parser);
    }
    setParsers(parsers);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (parser in ParsersList) {
      navigate(ParsersList[parser]);
    } else {
      setFieldErrors({ ...fieldErrors, parser: "Invalid Input" });
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="main-card">
        <div className="container-card">
          <h1 className="chooser-head">Choose Parser :</h1>
          <form onSubmit={handleSubmit}>
            <CustomInputAutocomplete
              options={parsers}
              onChange={(val) => {
                setFieldErrors((prev) => ({ ...prev, parser: "" }));
                setParser(val);
              }}
            />
            <span className="error">{fieldErrors.parser}</span>
            <Button
              className="btn-submit"
              type="submit"
              fullWidth
              disabled={isLoading}
              variant="contained"
              sx={{ mt: 1, mb: 1 }}
              onClick={handleSubmit}
            >
              {" "}
              {isLoading ? (
                <>
                  <CircularProgress
                    className="me-2 mb-1"
                    size={20}
                    style={{ color: "white", marginRight: "6px" }}
                  />
                  Loading
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
}
