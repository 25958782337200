import "./App.css";
import React, { useContext } from "react";
import Landing from "./pages/Landing";
import Parser from "./pages/Parser";
import BankStatement from "./pages/BankStatement";
import Aadhaar from "./pages/Aadhaar";
import Cheque from "./pages/Cheque";
import Pan from "./pages/Pan";
import PaySlip from "./pages/PaySlip";
import RC from "./pages/Rc"
import Invoice from "./pages/Invoice";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DocsUpload from "./components/DocsUpload";
import CreateCollection from "./pages/CreateCollection/CreateCollection";
// import UserContextProvider, { UserContext } from "./hooks/UserContext";  // provider vs user

function App() {
  return (
    <>
      <Router>
        {/* <UserContextProvider> */}
        {/* <KycContextProvider> */}
        <Routes>
          <Route exact path="/" element={<Landing />} />
          <Route path="/parser" element={<Parser />} />
          <Route path="/parser/bank-statement" element={<BankStatement />} />
          <Route path="/parser/aadhaar" element={<Aadhaar />} />
          <Route path="/parser/rc" element={<RC />} />
          <Route path="/parser/cheque" element={<Cheque />} />
          <Route path="/parser/pan" element={<Pan />} />
          <Route path="/parser/payslip" element={<PaySlip />} />
          <Route path="/parser/invoice" element={<Invoice />} />
          <Route path="/upload-docs" element={<DocsUpload />} />
          <Route path="/create-collection" element={<CreateCollection />} />
        </Routes>

        {/* <PermissionModal /> */}
        {/* </KycContextProvider> */}
        {/* </UserContextProvider> */}
      </Router>
    </>
  );
}

export default App;
