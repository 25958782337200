import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./sass/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/responsive.scss";
import App from "./App";
import GlobalContextProvider from "./hooks/GlobalContext.js";
import { SnackbarProvider } from "notistack";
import { Fade } from "@mui/material";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <SnackbarProvider
    maxSnack={2}
    TransitionComponent={Fade}
    autoHideDuration={2000}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
  >
    <GlobalContextProvider>
      <App />
    </GlobalContextProvider>
  </SnackbarProvider>
);
