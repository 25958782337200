import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Select, TextField, Grid, Button, InputLabel, MenuItem, FormControl, Autocomplete } from '@mui/material';


const baseurl = "https://prod.api.lms.embifi.in/api/v1/";

export default function CreateCollection() {
    const [isLoading, setIsLoading] = useState(false);
    const [apiRes, setApiRes] = useState({ message: "", link: "" });

    const [custIdList, setCustIdList] = useState([]);
    const [dealerList, setDealerList] = useState([]);

    const [collectionData, setCollectionData] = useState({
        application_id: "",
        anchor_id: "",
        paymentTo: "",
        paymentAmount: "",
        paymentType: "",
        paymentSeries: "",
        id: "",
        transactionId: "",
        transactionUtr: "",
        selletSettlementUtr: "",
        paymentMode: "",
        remarks: "",
        collectionDate: ""
    });

    const handleChange = (event) => {
        let { name, value } = event.target;
        setCollectionData({ ...collectionData, [name]: value })
    }

    const getCustomerId = () => {
        let customeridList = [];

        var config = {
            method: 'get',
            url: baseurl + 'common/get-loans',
            headers: {}
        };

        axios(config)
            .then((response) => {
                console.log(response)
                response?.data?.data?.map((val, ind) => {
                    if (val?.customerData?.is_corporate == false) {
                        customeridList = [...customeridList, { 'application_id': val?.application_id, 'label': `${val?.application_id} : ${val?.customerData?.pan_details?.name}` }]
                    }
                    else {
                        customeridList = [...customeridList, { 'application_id': val?.application_id, 'label': `${val?.application_id} : ${val?.customerData?.corporate_pan_details?.name}` }]
                    }
                })
                setCustIdList(customeridList)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const getAnchorId = () => {
        let anchorNameList = [];

        var config = {
            method: 'get',
            url: baseurl + 'common/get-anchors',
            headers: {}
        };

        axios(config)
            .then(function (response) {
                console.log('dealer data: ', response)
                response?.data?.data?.map((val, ind) => {
                    // anchorNameList = [...anchorNameList, val?.name]
                    anchorNameList = [...anchorNameList, { 'dealer_id': val?.anchor_id, 'label': `${val?.anchor_id} : ${val?.name}` }]

                })
                setDealerList(anchorNameList);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const handleSubmit = () => {
        setCollectionData({ ...collectionData, collectionDate: new Date() })
    }

    useEffect(() => {
        getCustomerId();
        getAnchorId();
    }, [])

    useEffect(() => {
        console.log(collectionData)
    }, [collectionData])

    return (
        <>
            {
                isLoading ?
                    <h1 style={{ color: '#fff' }}>Loading...</h1>
                    :
                    <div style={{ background: '#fff', minHeight: '100vh', minWidth: '100vw', padding: '2em', overflow: 'scroll' }}>

                        {
                            apiRes?.message ?
                                <div style={{ textAlign: 'center', marginBottom: '1em', padding: '0.5em', background: 'grey', borderRadius: '10px' }}>
                                    <h5>{apiRes?.message}</h5>
                                    <h5><a href={apiRes?.link} target="blank">{apiRes?.link}</a></h5>
                                </div>
                                : null}

                        <div className='d-flex justify-content-start flex-column align-items-center'>
                            <h2>Enter Collection Data</h2>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        disablePortal
                                        options={custIdList}
                                        fullWidth
                                        name="application_id"
                                        onChange={(e, newVal) => setCollectionData({ ...collectionData, application_id: newVal?.application_id })}
                                        renderInput={(params) => <TextField {...params} label="Customer Name - (Application ID)" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Autocomplete
                                        disablePortal
                                        options={dealerList}
                                        fullWidth
                                        name="anchor_id"
                                        onChange={(e, newVal) => setCollectionData({ ...collectionData, anchor_id: newVal?.application_id })}
                                        renderInput={(params) => <TextField {...params} label="Dealer Name" />}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Payment To" variant="outlined" value={collectionData?.paymentTo} name="paymentTo" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Payment Amount" variant="outlined" value={collectionData?.paymentAmount} name="paymentAmount" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Payment Type" variant="outlined" value={collectionData?.paymentType} name="paymentType" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Payment Series" variant="outlined" value={collectionData?.paymentSeries} name="paymentSeries" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="ID" variant="outlined" value={collectionData?.id} name="id" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Transaction ID / Transaction Ref Number" variant="outlined" value={collectionData?.transactionId} name="transactionId" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Transaction UTR" variant="outlined" value={collectionData?.transactionUtr} name="transactionUtr" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Seller Settlement UTR" variant="outlined" value={collectionData?.selletSettlementUtr} name="selletSettlementUtr" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Payment Mode" variant="outlined" value={collectionData?.paymentMode} name="paymentMode" onChange={handleChange} />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField fullWidth label="Remarks" variant="outlined" value={collectionData?.remarks} name="remarks" onChange={handleChange} />
                                </Grid>
                            </Grid>
                            <br />
                            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                        </div>

                    </div>
            }
        </>
    );
}