import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";

export default function CustomInputAutocomplete({ options, onChange: setVal }) {
  return (
    <Autocomplete
      sx={{
        "& input": {
          width: "100% !important",
        },
        marginTop: "30px",
      }}
      id="custom-input-demo"
      options={options}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <input
            placeholder="Select"
            type="text"
            {...params.inputProps}
            className="form-input"
          />
        </div>
      )}
      onChange={(e) => {
        setVal(e.target.innerText);
      }}
    />
  );
}
