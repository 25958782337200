import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, CircularProgress } from "@mui/material";
import pic from "../assets/embifi_logo.png";

export default function Landing() {
  const [showWelcomePage, setShowWelcomePage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowWelcomePage(false);
  //   }, 3000);
  // }, []);

  return showWelcomePage ? (
    <>
      <div className="main-card">
        <p className="welcome-head">Welcome!</p>
        <p className="chooser-head" style={{ textAlign: "center" }}>
          to
        </p>
        <div className="container-content-center">
          <img id="loading-embifi-logo" src={pic} alt="Embifi" />
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="main-card">
        {/* <div className="container-card"> */}
        <h1 className="chooser-head">Select Tool :</h1>
        <div className="d-flex justify-content-between">
          <Button
            // style={{ height: "50px" }}
            style={{ height: "100px", width: '250px' }}
            type="submit"
            fullWidth
            disabled={isLoading}
            variant="contained"
            sx={{ mt: 5, mb: 1 }}
            onClick={(e) => navigate("/parser")}
          >
            {" "}
            {isLoading ? (
              <>
                <CircularProgress
                  className="me-2 mb-1"
                  size={20}
                  style={{ color: "white", marginRight: "6px" }}
                />
                Loading
              </>
            ) : (
              "Parser"
            )}
          </Button>

          <Button
            // style={{ height: "50px" }}
            style={{ height: "100px", width: '250px' }}
            type="submit"
            fullWidth
            disabled={isLoading}
            variant="contained"
            sx={{ mt: 5, mb: 1 }}
            // onClick={(e) => navigate("/upload-docs")}
            onClick={(e) => window.open("https://www.embedded-tools.embifi.in/upload-documents", '_blank', 'noopener,noreferrer')}
          >
            {" "}
            {isLoading ? (
              <>
                <CircularProgress
                  className="me-2 mb-1"
                  size={20}
                  style={{ color: "white", marginRight: "6px" }}
                />
                Loading
              </>
            ) : (
              "Upload Docs"
            )}
          </Button>

          <Button
            // style={{ height: "50px" }}
            style={{ height: "100px", width: '250px' }}
            type="submit"
            fullWidth
            // disabled={isLoading}
            disabled
            variant="contained"
            sx={{ mt: 5, mb: 1 }}
            onClick={(e) => navigate("/create-collection")}
          >
            {" "}
            {isLoading ? (
              <>
                <CircularProgress
                  className="me-2 mb-1"
                  size={20}
                  style={{ color: "white", marginRight: "6px" }}
                />
                Loading
              </>
            ) : (
              "Create Collection (Coming Soon)"
            )}
          </Button>
        </div>
      </div>
    </>
  );
}
