import ZoonodleInc from "./PaySlips/Zoonodle_Inc/zoonodleInc";
import Other from "./PaySlips/OtherCompanies/other";

import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function PaySlip() {
  const [company, setCompany] = React.useState("");

  const components = {
    Zoonodle_Inc: <ZoonodleInc />,
    Other: <Other />,
  };

  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <h1> Choose Your company </h1>
        <Box sx={{ minWidth: 120 }} style={{ width: "50vw" }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Company</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={company}
              label="company"
              onChange={handleChange}
            >
              <MenuItem value={"Zoonodle_Inc"}>Zoonodle Inc</MenuItem>
              <MenuItem value={"Other"}>Other</MenuItem>
            </Select>
          </FormControl>
        </Box>

        <div style={{ height: "30px" }}></div>

        {company ? components[company] : <></>}
      </div>
    </>
  );
}
